import {gql} from 'apollo-boost'

const fragments = `
{
    id
    name
    img_url
    lazy_url
    status
    weight
    units
    minimum
    usage
    price
    product_type{
        id
        name
        slug
        categories{
        name
        }
    }
    category{
        id
        name
        slug
    }
    company{
        id
        name
        location
        phone
    }
    updatedAt
    createdAt
}
`
export const create = gql`
    mutation createProduct($data: ProductCreateInput!){
        createProduct(data: $data)${fragments}
    }
`
export const get = gql`
    query products($where: ProductWhereInput ){
        products(where: $where)${fragments}
    }
`

export const update = gql`
    mutation updateProduct(
        $data: ProductUpdateInput!, 
        $where: ProductWhereUniqueInput!
    ){
        updateProduct(data: $data, where: $where)${fragments}
    }
`

export const updateDelete = gql`
    mutation updateDeleteProduct(
        $where: ProductWhereUniqueInput!
    ){
        updateProduct(where: $where, data: {
            status: "deleted"
        }){
            id
        }
    }
`

export const remove = gql`
    mutation deleteProductType($where: ProductWhereUniqueInput!){
        deleteProduct(where: $where)${fragments}
    }
`



function getQuery(where) {
    let query = `
        query products {
            products(where: {
                company: {
                    name: "Akoko Market"
                }
    `;

    if (where) {
        query += `,
                ${where}
        `;
    }

    query += `
            })${fragments}
        }
    `;

    return query;
}